import React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

const User = gql`
  fragment User on User {
    agency_id
    authentication_token
    business_id
    device_tokens
    digest_email_subscription
    email
    guest
    id
    name
    phone
    receive_billing_notifications
    status
    type
    setup_step
    referral_code
    time_zone
    analytics_enabled
    topic_monitoring_enabled
    genai_settings_enabled
    new_report_panel
    post_campaign_filter_user
    sentiment_chart_enabled
    topic_monitoring_only_user
    linkedin_asset_management_enabled
    expensive_analytics_enabled
    agencies
    is_respo_user
  }
`;

const M_LOGIN = gql`
  mutation Login($email: String, $password: String) {
    user(email: $email, password: $password)
      @rest(type: "User", path: "/users/me", method: "GET") {
      ...User
    }
  }
  ${User}
`;

const Q_CURRENT_USER = gql`
  query CurrentUser {
    currentUser @rest(type: "User", path: "/users/me") {
      ...User
    }
  }
  ${User}
`;

const M_CREATE_USER = gql`
  mutation CreateUser($input: User!) {
    createUser(input: $input)
      @rest(type: "User", path: "/users", method: "POST") {
      ...User
    }
  }
  ${User}
`;

const M_UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $input: User!) {
    user(id: $id, input: $input)
      @rest(type: "User", path: "/users/{args.id}", method: "PUT") {
      ...User
    }
  }
  ${User}
`;

const LoginMutation = ({ children, ...rest }) => (
  <Mutation mutation={M_LOGIN} {...rest}>
    {children}
  </Mutation>
);

const CurrentUser = ({ children }) => (
  <Query query={Q_CURRENT_USER}>{children}</Query>
);

const CreateUserMutation = ({ children, ...rest }) => (
  <Mutation mutation={M_CREATE_USER} {...rest}>
    {children}
  </Mutation>
);

const UpdateUserMutation = ({ children }) => (
  <Mutation mutation={M_UPDATE_USER}>{children}</Mutation>
);

export {
  CurrentUser,
  CreateUserMutation,
  UpdateUserMutation,
  LoginMutation,
  M_LOGIN,
  Q_CURRENT_USER,
  M_CREATE_USER,
  M_UPDATE_USER,
};
